VRA.pageEvents = [];
VRA.logPageEvent = function(event, extraData) {
  var logEntry = {
    time : VRA.timerElapsed("pageEvents").toFixed(0),
    event : event
  };
  VRA.pageEvents.push(logEntry);
  ga("send", "timing", "Page Load", logEntry.event, logEntry.time, VRA.mostDependenciesCached ? 'Cached' : 'Not Cached');
  if (VRA.dbg)
    console.log((logEntry.time/1000).toFixed(2) + ": " + logEntry.event, extraData || "");
};
VRA.getPageEvents = function() {
  VRA.logPageEvent("this error");
  return {
    pageEvents : VRA.pageEvents
  };
};
VRA.sendToRaygun = function(e, custom) {
  if (typeof Raygun != "undefined" && VRA.toType(Raygun) == "object") {
    custom = custom || {};
    $.extend(custom, VRA.getPageEvents());
    Raygun.send(e, custom);
  }
};
VRA.getIEVer = function(ua,re) {
  var rv = -1;
  if (navigator.appName == "Microsoft Internet Explorer") {
    ua = navigator.userAgent,
    re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
    if (re.exec(ua) != null)
      rv = parseFloat( RegExp.$1 );
  }
  return rv;
};
VRA.ieVer = VRA.getIEVer();
VRA.timerStart("pageEvents");