/*
 * Loads external CSS files for current page.
 * 
 * Public website always has a combined 'all.css' file which is quite large (~260k).
 * When viewport is less than 768px wide, load the appropriate css file for the page type:
 *
 * Dependencies: verge.js, instance of SiteUsageCookieManager as VRA.SUCM, loadCSS, onloadCSS.
 */
;
/**
 * @array urls  urls of every css file to load for current page
 */
function PublicCssLoader(urls) {
  
  var _this = this;
  this.currentUrls = {};
  this.currentMainCssType = '';
  this.cssModTime = 0;
  
  this.init = function(urls) {
    if(VRA.toType(urls)=='array') {
      var info;
      urls.forEach(function(url) {
        info = _this.getCssInfo(url);
        _this.currentUrls[info.type] = info.url;
      });
    }
  };
  
  this.getUrlBaseName = function(url) {
    return typeof url=='string' ? url.replace(/^.*\/|\.[^.]*$/g, '') : '';
  };
  
  this.getCssInfo = function(url) {
    var type;
    if(url.indexOf('all_')>-1) {
      if(verge.viewportW() < 768) {
        if(VRA.preHeader == 'home')
          type = 'home';
        else if(VRA.preHeader == 'rental' || VRA.preHeader == 'asset' || VRA.preHeader == 'general_contact')
          type = 'detail';
        else
          type = 'listings';
        url = url.replace('all_',type+'-mobile-deferred_');
      } else {
        type = 'all'
      }
      this.currentMainCssType = type;
    } else {
      type = this.getUrlBaseName(url);
    }
    return {type:type,url:url};
  };
  
  this.getCssTypeFromUrl = function(url) {
    return this.getUrlBaseName(url).replace(/(-mobile-deferred)*_\d+/,'');
  };
  
  this.currentCssLoadedBefore = function() {
    return this.currentMainCssType && VRA.SUCM.get('cssLoaded.'+this.currentMainCssType)===true;
  };
  
  /*
   * Load css for current page
   */
  this.loadCurrentCss = function() {
    if(VRA.ieVer == -1 || VRA.ieVer>9) {
      var cssInfo, url, type, baseName;
      for(type in this.currentUrls) {
        url = this.currentUrls[type];
        VRA.logPageEvent('Load '+type+' css');
        this.loadCss(url,function(cssInfo){
          VRA.logPageEvent('Loaded '+cssInfo.type+' css');
          if(_this.currentMainCssType == cssInfo.type) {
            _this.cssModTime = cssInfo.modTime;
            document.getElementsByTagName('body')[0].classList.add('main-css-loaded');
            if(VRA.publicWebsite)
              VRA.publicWebsite.mainStylesheetLoaded();
            _this.recordCssLoad(_this.currentMainCssType);
          }
        });
      };
    }
  };
  
  /*
   * Requires jQuery
   */
  this.loadOtherMobileCss = function() {
    var 
    cssTypes = {
      critical: ['contact','detail-asset','detail-property','home','listings-assets','listings-properties','search'],
      deferred: ['home','listings','detail']
    },
    otherCssUrls = [],
    cssLoaded = VRA.SUCM.get('cssLoaded') || {};
    if(VRA.criticalCssType) {
      // Load other critical css:
      $.each(cssTypes.critical, function(i,cssType) {
        if(cssType != VRA.criticalCssType && !cssLoaded[cssType]) {
          VRA.logPageEvent('Preloading critical '+cssType+' css');
          otherCssUrls.push(VRA.dirWsCss+'public/'+VRA.site+'/critical/'+cssType+'_'+_this.cssModTime+'.css');
        }
      });
    }
    if(_this.currentMainCssType) {
      // Load other deferred css:
      $.each(cssTypes.deferred, function(i,cssType) {
        if(cssType != _this.currentMainCssType && !cssLoaded[cssType]) {
          VRA.logPageEvent('Preloading deferred '+cssType+' css');
          otherCssUrls.push(VRA.dirWsCss+'public/'+VRA.site+'/'+cssType+'-mobile-deferred_'+_this.cssModTime+'.css');
        }
      });
    }
    $.each(otherCssUrls, function(i,otherCssUrl) {
      $.ajax({
        url:otherCssUrl
      }).done(function(data,textStatus,jqXHR){
        var cssType = _this.getCssTypeFromUrl(this.url);
        VRA.logPageEvent('Preloaded '+($.inArray(cssType, cssTypes.critical)===-1 ? 'deferred' : 'critical')+' '+cssType+' css');
        _this.recordCssLoad(cssType);
      });
    });
  };
  
  this.loadCss = function(url,cb) {
    onloadCSS(loadCSS(url), function(cssLink) {
      if(typeof cssLink == 'object') {
        var baseName = _this.getUrlBaseName(cssLink.href);
        cb({
          baseName : baseName,
          type : baseName.replace(/-mobile-deferred/,'').replace(/_\d+/,''),
          modTime : baseName.replace(/[^\d]/g,'')
        });
      }
    });
  };
  
  this.recordCssLoad = function(type) {
    if(VRA.SUCM.get('cssLoaded')) {
      VRA.SUCM.set('cssLoaded.'+type,true,30);
    } else {
      var recs = {};
      recs[type] = true;
      VRA.SUCM.set('cssLoaded',recs,30);
    }
  };
  
  this.init(urls);

};