function SiteUsageCookieManager() {

  this.cookieNamePrefix = 'siteUsageData-';
  
  this.set = function(dotNotKey, val, expires) {
    var 
    parts = dotNotKey.split('.',2),
    suffix = parts[0],
    addr = parts[1],
    data = this.get(suffix),
    dataType = VRA.toType(data),
    attributes = {},
    cmd;
    if(dataType=="object" && addr) {
      cmd = 'data';
      addrs = addr.split('.');
      addrs.forEach(function(addr){
        cmd += "['"+addr+"']";
      });
      cmd += ' = val;';
    } else {
      cmd = 'data = val;';
    }
    eval(cmd);
    if(typeof expires === 'number')
      attributes.expires = expires;
    Cookies.set(this.cookieNamePrefix+suffix, data, attributes);
  };
  
  this.get = function(dotNotKey) {
    var 
    firstDotPos = dotNotKey.indexOf('.'),
    suffix,
    addr,
    json,
    cmd,
    result;
    if(firstDotPos == -1) {
      suffix = dotNotKey;
    } else {
      suffix = dotNotKey.substr(0,firstDotPos);
      addr = dotNotKey.substr(firstDotPos+1);
    }
    json = Cookies.get(this.cookieNamePrefix+suffix) || '';
    if(json) {
      try {
        var data = JSON.parse(json);
      } catch (e) {
        data = json;
      }
      if(addr && VRA.toType(data)=="object") {
        cmd = 'data.'+addr;
        try {
          result = eval(cmd);
        } catch(e) {
          VRA.logPageEvent('SiteUsageCookieManager error evaling "'+cmd+'" from '+suffix,e);
          result = null;
        }
      } else {
        result = data;
      }
    }
    return result;
  };
	
}